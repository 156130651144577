<template>
	<div>
		<Header title='我的收货地址'></Header>
		<div class="addressTop">
			<img src="../../assets/image/luckDraw/address.png" style="width: 43px;height: 44px;">
			<div style="margin-left: 16px;">
				<div v-if="info.userName">
					<div class="flex align-center">
						<div style="width: #434444;font-size: 14px;font-weight: bold;">{{info.userName}}</div>
						<div style="color: #8E8E8E;font-size: 14px;margin-left: 12px;">{{info.userPhone}}</div>
					</div>
					<div style="margin-top: 6px;color: #434444;font-size: 12px;">{{info.area}}{{info.address}}</div>
				</div>
				<div v-else style="font-size: 16px;font-weight: bold;color: #2B2A25;">暂无地址</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		userAddress
	} from '../../api/mine.js'
	import Header from '@/components/header.vue'
	export default {
		name: "myAddress",
		data() {
			return {
				token: window.localStorage.getItem('token'),
				info:{}
			}
		},
		components: {
			Header
		},
		created() {
			this.getAddress()
		},
		methods: {
			getAddress() {
				let data = new FormData()
				data.append('token', this.token)
				userAddress(data).then(res => {
					console.log('地址', res)
					this.info = res.data
				})
			}
		}
	}
</script>

<style scoped>
	.flex {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.addressTop {
		height: 100px;
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(192, 191, 191, 0.5);
		border-radius: 8px;
		margin: 13px 16px 0 16px;
		padding: 0 26px 0 14px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
	}
</style>
